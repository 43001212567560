<template>
    <div class="contact">
        <section class="hero light-bg bg5">
            <div class="container">
                <div class="row">
                    <div class="col-xl-6 offset-xl-3 text-center">
                        <h1 class="mt-100">{{ $t("Kapcsolat") }}</h1>
                    </div>
                </div>
                <div class="row my-5">
                    <div class="col-xl-8">
                        <div class="white-box-simple">
                            <div class="h4">
                                {{ $t("Lépj velünk kapcsolatba!") }}
                            </div>
                            <div class="row">
                                <div class="col-xl-7">
                                    <p>
                                        {{
                                            $t(
                                                "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam"
                                            )
                                        }}
                                    </p>
                                </div>
                            </div>

                            <div class="mt-5">
                                <input
                                    type="text"
                                    v-model="contact.firstname"
                                    class="form-control input-bordered mb-5"
                                    :placeholder="$t('Vezetéktnév*')"
                                />
                                <input
                                    type="text"
                                    v-model="contact.lastname"
                                    class="form-control input-bordered mb-5"
                                    :placeholder="$t('Keresztnév*')"
                                />
                                <input
                                    type="email"
                                    v-model="contact.email"
                                    class="form-control input-bordered mb-5"
                                    :placeholder="$t('Email*')"
                                />
                                <textarea
                                    class="form-control input-bordered mb-5"
                                    v-model="contact.message"
                                    :placeholder="$t('Üzenet szövege*')"
                                    rows="6"
                                ></textarea>
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="aszf"
                                        v-model="contact.aszf"
                                    />
                                    <label class="form-check-label" for="aszf">
                                        <small
                                            >{{
                                                $t("Elolvastam és elfogadom az")
                                            }}
                                            <a
                                                href="/privacy"
                                                target="_blank"
                                                >{{
                                                    $t(
                                                        "Adatvédelmi Szabályzatot"
                                                    )
                                                }}</a
                                            >.</small
                                        >
                                    </label>
                                </div>
                                <div
                                    v-if="success"
                                    class="alert alert-success mt-2"
                                >
                                    {{
                                        $t(
                                            "Köszönjük a megkeresést. Hamarosan felvesszük veled a kapcsolatot!"
                                        )
                                    }}
                                </div>
                                <div class="text-end mt-5">
                                    <button
                                        v-if="!success"
                                        @click="submitContact"
                                        type="submit"
                                        class="btn btn-primary"
                                    >
                                        {{ $t("Küldés") }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 offset-xl-1">
                        <div class="h4 mt-5 mt-xl-0">
                            {{ $t("Névmatrica") }}
                        </div>
                        <p class="mt-5">
                            <strong>{{ $t("Címünk") }}</strong> <br />
                            {{ $t("2800 Tatabánya") }}<br />
                            {{ $t("Sárberek 302. 10/44.") }}
                        </p>

                        <p class="mt-5">
                            <strong>{{ $t("Elérhetőségeink") }}</strong> <br />
                            {{ $t("hello@nevmatrica.hu") }} <br />
                            {{ $t("+36 70 282 9270") }}
                        </p>
                        <div class="mt-5">
                            <div class="circle color-blue">
                                <a href="#"><img src="/img/icon-fb.svg" /> </a>
                            </div>
                            <div class="circle color-yellow">
                                <a href="#"
                                    ><img src="/img/icon-insta.svg" />
                                </a>
                            </div>
                            <div class="circle color-orange">
                                <a href="#"
                                    ><img src="/img/icon-youtube.svg" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="py-0">
            <div class="row">
                <div class="col-4">
                    <img src="/img/17.png" class="w-100" />
                </div>
                <div class="col-4">
                    <img src="/img/18.png" class="w-100" />
                </div>
                <div class="col-4">
                    <img src="/img/19.png" class="w-100" />
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: "Contact",

    data() {
        return {
            contact: {},
            success: false
        };
    },
    computed: {
        url: function() {
            return `${process.env.VUE_APP_API_URL}`;
        }
    },
    methods: {
        submitContact() {
            this.$http.post(this.url + "/contact", this.contact).then(() => {
                this.success = true;
                this.contact = {};
            });
        }
    }
};
</script>

<style scoped></style>
